<template>
  <article class="gradient-border shadow-quicklink sm:hover:shadow-xl transition duration-300 ease-in-out">
    <div class="pt-2px h-full">
      <div class="bg-white h-full pt-5 pb-0 sm:py-6 px-3 lg:px-5 sm:pl-5 font-semibold font-heading text-base sm:text-md md:text-lg">
        <div class="h-full pb-5 sm:p-0">
          <div class="flex flex-col justify-between h-full">
            <div>
              <div>{{ title }}</div>
              <div class="text-sm font-body font-light py-3 md:pr-3" v-html="content"/>
            </div>
            <btn :text="true" :link="link" color="transparent" txt-color="secondary" hover-color="transparent" hover-txt-color="secondary">
              Lees meer
            </btn>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Btn from '~/components/base/Btn';

export default {
  name: 'NewsItem',
  components: {Btn},
  props: {
    link: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'News title',
    },
    content: {
      type: String,
      default: 'News content',
    },
  },
};
</script>
