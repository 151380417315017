<template>
  <div id="newsitems" class="bg-secondary-light">
    <div class="container pt-4 pb-8 sm:py-10">
      <div class="h2 pb-6">
        Nieuwsberichten
      </div>
      <div class="flex flex-col md:flex-row justify-between -m-4">
        <!--TODO: show 2 newsitems on mobile-->
        <news-item
          v-for="item in news"
          :key="item.title"
          :link="`/nieuws/${item.permalink}`"
          :title="item.title"
          :content="item.short_text"
          class="flex-1 m-4 last:hidden sm:last:flex"
        />
      </div>
      <btn link="/nieuws" class="mt-8">
        Meer nieuws
      </btn>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import NewsItem from '~/components/news/NewsItem';
import Btn from '~/components/base/Btn';

export default {
  components: {Btn, NewsItem},
  computed: {
    ...mapState({
      news: state => state.news,
    }),
  },
};
</script>
